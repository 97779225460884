/* eslint-disable react/jsx-pascal-case */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Flex, Box, BaseStyles } from "theme-ui"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import MarkdownConverter from "../components/markdown-converter/markdown-converter"

const TestMarkdownBody = ({ content }) => (
  <Flex mx="auto" sx={{ maxWidth: "containerWidth" }}>
    <Box mt="0" mb="2rem" px="defaultPadding">
      <MarkdownConverter content={content} />
    </Box>
  </Flex>
)

TestMarkdownBody.propTypes = {
  content: PropTypes.string.isRequired,
}

const TestMarkdown = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout includeNav>
      <Metadata title={post.frontmatter.title} article />
      <BaseStyles>
        <TestMarkdownBody content={post.html} />
      </BaseStyles>
    </Layout>
  )
}

TestMarkdown.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
}

export default TestMarkdown

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
      }
    }
  }
`
